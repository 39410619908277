import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { navigate, Link } from "gatsby";
import PlainPage from "../../templates/plain-page";
import { db } from "../../components/firebase/firestore";

const Content = ({ currentUser, location, rereadRefUid }) => {
  const [message, setMessage] = useState();

  useEffect(() => {
    if (currentUser !== 0) {
      const urlParams = new URLSearchParams(location.search);
      const refUid = urlParams.get("u");
      if (!currentUser && refUid) {
        const userRef = db.collection("users").doc(refUid);
        userRef.get().then(doc => {
          if (doc.exists) {
            window.localStorage.setItem("ref_uid", refUid);
            rereadRefUid();
            setMessage(
              <span>
                Congratulations! Your spot has been temporarily reserved.
                <br />
                <Link to="/register">Register</Link> now to confirm it or learn
                more about <Link to="/">OCCASIO</Link>.
              </span>,
            );
          } else {
            setMessage(
              "Referral link is incorrect. Please make sure you followed the correct link.",
            );
          }
        });
      }
      if (!refUid) {
        setMessage(
          "Referral link is incorrect. Please make sure you followed the correct link.",
        );
      }
      if (currentUser) {
        setMessage("Logged in users can't use referral links.");
      }
    }
  }, [currentUser]);

  return (
    <p className="is-size-4 has-text-centered">
      {message ? (
        <span> {message}</span>
      ) : (
        <div className="button is-loading-spinner">Loading</div>
      )}
    </p>
  );
};

const Ref = ({ location }) => {
  return <PlainPage Content={Content} location={location} isCentred />;
};

export default Ref;
